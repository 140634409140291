<template>
    <div>
        <v-btn @click="download" v-text="'Download'"/>
        hue:
        <input v-model="hue" type="range" min="0" max="259" step="1"/>

        <v-stage :config="config.konva" ref="konvaStage">
            <v-layer>
                <v-image ref="konvaImage" :config="config.image"/>
                <v-rect :config="config.rect"/>
                <v-image ref="photo" :config="config.photo"/>
                <v-image ref="logo" :config="config.logo"/>
                <v-text ref="company" :config="config.company"/>
                <v-text ref="name" :config="config.fullName"/>
                <v-text ref="job_title" :config="config.jobTitle"/>
                <v-text ref="registration_id" :config="config.registrationId"/>
                <v-text ref="email" :config="config.email"/>
                <v-text ref="website" :config="config.website"/>
            </v-layer>
        </v-stage>
    </div>
</template>

<script>
import KonvaHelper from "@/helpers/KonvaHelper";
import Konva from "konva";

export default {
    name: 'PhotoEditor',
    props: {
        photoUrl: {
            type: String,
            required: true
        },
        canvasWidth: {
            type: Number,
            default: 400,
        },
        canvasHeight: {
            type: Number,
            default: 800,
        },
        personalization: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            hue: 150,
            config: {
                konva: {
                    width: this.canvasWidth,
                    height: this.canvasHeight
                },
                rect: {
                    x: 0,
                    y: 50,
                    width: 100,
                    height: 100,
                    fill: 'red'
                },
                image: {
                    image: null,
                    filters: [Konva.Filters.HSL]
                },
                logo: {
                    x: 10,
                    y: 50,
                    width: 40,
                    height: 40,
                    image: null,
                    draggable: true
                },
                photo: {
                    x: 10,
                    y: 50,
                    width: 40,
                    height: 40,
                    image: null,
                    draggable: true
                },
                company: {
                    fontSize: 18,
                    fill: '#FFFFFF',
                    x: 70,
                    width: 200,
                    height: 60,
                    text: 'blabla inc.',
                    draggable: true
                },
                fullName: {
                    fontSize: 18,
                    fill: '#FFFFFF',
                    x: 70,
                    height: 60,
                    text: 'blabla inc.',
                    draggable: true
                },
                jobTitle: {
                    fontSize: 18,
                    fill: '#FFFFFF',
                    x: 70,
                    height: 60,
                    text: 'blabla inc.',
                    draggable: true
                },
                registrationId: {
                    fontSize: 18,
                    fill: '#FFFFFF',
                    x: 70,
                    height: 60,
                    text: 'blabla inc.',
                    draggable: true
                },
                email: {
                    fontSize: 18,
                    fill: '#FFFFFF',
                    x: 70,
                    height: 60,
                    text: 'blabla inc.',
                    draggable: true
                },
                website: {
                    fontSize: 18,
                    fill: '#FFFFFF',
                    x: 70,
                    height: 60,
                    text: 'blabla inc.',
                    draggable: true
                },
            }
        }
    },

    mounted() {
        this.initEditor();
    },

    methods: {
        download() {
            KonvaHelper.downloadKonvaImage(this.$refs.konvaStage, 'infographic.png')
        },

        async initEditor() {
            let width = this.canvasWidth;
            let image = await KonvaHelper.loadImage(this.photoUrl, width)
            let logo = await KonvaHelper.loadImage(this.personalization.logo_url)
            let photo = await KonvaHelper.loadImage(this.$auth.user().avatar_url)

            this.config.image.image = image;

            this.config.photo.image = photo;
            this.config.photo.y = image.height + 20;

            this.config.logo.image = logo;
            this.config.logo.x = width - 50;
            this.config.logo.y = image.height + 20;

            this.config.company.y = image.height + 10;
            this.config.company.text = this.personalization.company;

            this.config.fullName.y = image.height + 30;
            this.config.fullName.text = this.personalization.name;

            this.config.jobTitle.y = image.height + 50;
            this.config.jobTitle.text = this.personalization.job_title;

            this.config.konva.width = width
            this.config.konva.height = image.height + 100

            this.config.registrationId.text = this.personalization.registered_id;
            this.config.registrationId.y = image.height + 70;

            this.config.email.text = this.personalization.email;
            this.config.email.y = image.height + 85;

            this.config.website.text = this.personalization.website;
            this.config.website.y = image.height + 105;

            this.config.rect.y = image.height
            this.config.rect.width = width
            this.config.rect.fill = this.personalization.color
        },

        changeHue() {
            let update = () => {
                const imgNode = this.$refs.konvaImage.getNode();
                imgNode.hue(parseFloat(this.hue) );
                imgNode.getLayer().draw();
                imgNode.cache();
            }

            update();
        }
    },
    watch: {
        hue() {
            this.changeHue();
        }
    }
}
</script>

<style>

</style>
