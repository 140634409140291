<template>
    <user-dashboard-layout>
        <v-container v-if="marketReport.id">
            <h1>{{ marketReport.created_at | month }}</h1>
            <p>Click on a slide to view a larger version of it. To download an individual slide, click the “Download” button to the right of the selected slide.</p>

            <v-row>
                <v-col v-for="(slide, index) in marketReport.individual_slides" md="6" xl="3" :key="index">
                    <v-card max-width="344" class="mx-auto" @click="openModal(slide)">
                        <v-img :src="slide" height="200px"/>
                    </v-card>
                </v-col>
            </v-row>

            <v-dialog class="mmr-modal" v-model="dialog.opened" width="1000px">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col md="7" ref="editorContainer">
                                <v-img
                                    v-if="!dialog.editMode"
                                    ref="editorContainer"
                                    max-height="600"
                                    contain
                                    :src="dialog.url"/>
                                <div v-else>
                                    <PhotoEditor
                                        ref="editor"
                                        :personalization="personalization"
                                        :canvas-width="400"
                                        :canvas-height="600"
                                        :photo-url="dialog.url.replace(/^.*?:\/\/.*?(?=\/|$)/,'')"/>
                                </div>
                            </v-col>
                            <v-col md="5">
                                <v-switch
                                    color="primary"
                                    @change="dialog.editMode = !dialog.editMode"
                                    label="Personalization"/>

                                <v-list v-if="dialog.editMode" shaped>
                                    <v-list-item-group v-model="personalizationList" multiple>
                                        <template v-for="(item, i) in items">
                                            <v-divider v-if="!item" :key="`divider-${i}`"/>
                                            <v-list-item
                                                v-else
                                                :key="`item-${i}`"
                                                :value="item"
                                                active-class="deep-purple--text text--accent-4">
                                                <template v-slot:default="{ active }">
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="item"/>
                                                    </v-list-item-content>

                                                    <v-list-item-action>
                                                        <v-checkbox
                                                            @change="toggleDisplay(item)"
                                                            :input-value="active"
                                                            color="deep-purple accent-4"/>
                                                    </v-list-item-action>
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </v-list-item-group>
                                </v-list>
                                <v-btn color="green darken-1" block text @click="dialog.opened = false">{{$t('market.' + market.btncls)}}</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-row class="justify-end">
                <v-btn color="primary" @click="$router.push({name: 'dashboard.market-reports'})">{{$t('market.' + market.btnmmr)}}</v-btn>
            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>

import * as dayjs from 'dayjs';
import MarketReport from "@/models/MarketReport";
import UserDashboardLayout from "@/layouts/UserDashboardLayout";
import KonvaHelper from "@/helpers/KonvaHelper";
import User from "@/models/User";
import PhotoEditor from "@/components/PhotoEditor";
import market from "@/configs/market";

export default {
    name: 'Show',
    components: {
        PhotoEditor,
        UserDashboardLayout
    },
    data() {
        return {
            marketReport: {},
            selectedLang: 'en',
            personalization: {},
            items: ['logo', 'company', 'name', 'job_title', 'photo', 'registration_id', 'email', 'website'],
            personalizationList: ['logo', 'company', 'name', 'job_title', 'photo', 'registration_id', 'email', 'website'],
            dialog: {
                url: '',
                opened: false,
                editMode: false,
            },
            market
        }
    },
    async mounted() {
        this.marketReport = await MarketReport.find(this.$route.params.id)

        if (this.$route.params.lang) {
            this.selectedLang = this.$route.params.lang;
        }

        const user = await User.include(['personalization']).find(this.$auth.user().id)
        this.personalization = user.personalization
    },
    methods: {
        openModal(slide) {
            this.dialog.url = slide;
            this.dialog.opened = true;
        },
        handleDownloadKonva() {
            window.axios.post('/api/market-reports/' + this.marketReport.id + '/increment-downloads')
            KonvaHelper.downloadKonvaImage(this.$refs.konvaStage, 'mmr.png')
        },
        initEditor() {
            this.dialog.editMode = true;
        },
        toggleDisplay(item) {
            let refNode = this.$refs.editor.$refs[item.toLowerCase()].getNode();

            if (this.personalizationList.indexOf(item) > -1) {
                refNode.hide()
            } else {
                refNode.show()
            }

            refNode.getLayer().draw()
            refNode.cache()
        },
    },
    filters: {
        month(date) {
            return dayjs(date).format('MMMM, YYYY');
        }
    }
}
</script>

<style lang="scss">

</style>
